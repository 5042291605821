import menuControls from './main-menu';
import searchBox from './search-box';

const mainHeader = (() => {
  const setHeaderHeightVariable = () => {
    const $window = $(window);
    const $body = $('body');
    const $mainHeader = $('.main-header');

    let timer = null;

    $body.css('--main-header-height', `${$mainHeader.outerHeight()}px`);

    $window.on('resize', () => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        $body.css('--main-header-height', `${$mainHeader.outerHeight()}px`);
      }, 250);
    });
  };

  const initMainMenuControls = () => {
    const MAIN_MENU_OPENED_CLASS_NAME = 'main-menu-active';
    const $window = $(window);
    const $htmlBody = $('html, body');
    const $menuOpener = $('.menus-opener');
    const $mainMenuHolder = $('.main-header__menus');

    if ($window.width() > 1024) {
      $menuOpener.attr('aria-expanded', 'true');
      $mainMenuHolder.attr('aria-hidden', 'false');
    }

    $menuOpener.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const $mainHeader = $('.main-header');
      const $alertHeight = $('.alert-display').outerHeight() || 0;
      const $body = $('body');

      $body.css(
        '--main-header-bottom-offset',
        `${$mainHeader.outerHeight() + $alertHeight - $(window).scrollTop()}px`,
      );
      if ($menuOpener.hasClass(MAIN_MENU_OPENED_CLASS_NAME)) {
        $htmlBody.removeClass(MAIN_MENU_OPENED_CLASS_NAME);

        $menuOpener
          .removeClass(MAIN_MENU_OPENED_CLASS_NAME)
          .attr('aria-expanded', 'false')
          .find('.visually-hidden')
          .text('open menu');

        $mainMenuHolder.attr('aria-hidden', 'true');
      } else {
        $htmlBody.addClass(MAIN_MENU_OPENED_CLASS_NAME);

        $menuOpener
          .addClass(MAIN_MENU_OPENED_CLASS_NAME)
          .attr('aria-expanded', 'true')
          .find('.visually-hidden')
          .text('close menu');
        if ($(window).width() > 699 && $(window).width() < 1024) {
          $('#btn-1:not(.submenu-opened)').click();
        }

        if ($('.alert__content').is(':visible')) {
          $('.main-header__menus').height(
            $(window).height() - $('.alert').outerHeight() - $('.main-header__content-holder').outerHeight(),
          );
        } else {
          $('.main-header__menus').height(
            $(window).height() - 80,
          );
        }
        $mainMenuHolder.attr('aria-hidden', 'false');
      }
    });
  };

  const init = () => {
    setHeaderHeightVariable();
    initMainMenuControls();
    menuControls.init();
    searchBox.init();

    function alertClose() {
      if (sessionStorage.alertClosed !== 'true') {
        sessionStorage.alertClosed = 'true';
      }
      const alertHeight = $('.alert').outerHeight();

      $('.alert').slideUp();
      $('body').css('--main-header-bottom-offset', 0);
      $('.main-menu__list').css(
        'height',
        $(window).height() - $('.main-header').height(),
      );

      $('.main-header__menus').css('height', $(window).height())
    }

    $('.alert-close').on('click', alertClose);
    if (sessionStorage.alertClosed !== 'true') {
      $('.alert').show();
    }
  };

  return {
    init,
  };
})();

export default mainHeader;

/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const storyCarousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.story-carousel__wrapper').on('init', function () {
      const $nextSlide = $(
        $('.story-carousel__wrapper').find('.slick-slide'),
      ).eq(1);
      $nextSlide.addClass('nextSlide');
      $(this).closest('.story-carousel__holder').find('.btn-wrap').appendTo($(this));
    });
    const $carousel = $('.story-carousel__wrapper').slick({
      dots: false,
      infinite: false,
      speed: 300,
      fade: true,
      slidesToShow: 1,
      prevArrow: $('.prevArrow'),
      nextArrow: $('.nextArrow'),
    });

    $carousel.on('afterChange', (event, slick, currentSlide) => {
      const $nextSlide = $($carousel.find('.slick-slide')).eq(currentSlide + 1);
      setTimeout(() => {
        $('.nextSlide').removeClass('nextSlide');
        $nextSlide.addClass('nextSlide');
      }, 0);
    });

  }

  const init = () => {
    if (!$('.story-carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default storyCarousel;

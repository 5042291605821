const goalSelector = (() => {
  const initGoalSelector = () => {
    $('.toggle-subfilters.tab').on('click', function (e) {
      if ($(this).attr('aria-selected') === 'true') {
        e.preventDefault();
        return;
      }
      const id = $(this).attr('id');
      $('.toggle-subfilters.tab').attr('aria-selected', 'false');
      $(this).attr('aria-selected', 'true');
      $('.form__item').fadeOut(300);
      $(`div[aria-labelledby="${id}"]`).delay(300).fadeIn();
      e.preventDefault();
    });

    $('.toggle-subfilters:not(.tab)').on('click', function (e) {
      $('.pathway-programs__panels .form__item').slideUp();
      $(this).next().slideToggle();
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        $(this).attr('aria-expanded', 'true');
      } else {
        $(this).attr('aria-expanded', 'false');
      }
      e.preventDefault();
    });

    function showGoals() {
      $('.interest-section').fadeOut(300);
      $('.goal-selector__section').delay(300).fadeIn(300);

      $('#interests-btn')
        .attr('aria-current', 'false')
        .removeClass('enabled')
        .addClass('disabled');
      $('#goals-btn').removeClass('disabled').addClass('enabled');
      $('#goals-btn').attr('aria-current', 'true');
    }

    function showInterests() {
      $('.goal-selector__section').fadeOut(300);
      $('.interest-section').delay(300).fadeIn(300);

      $('#goals-btn')
        .attr('aria-current', 'false')
        .removeClass('enabled')
        .addClass('disabled');
      $('#interests-btn').removeClass('disabled').addClass('enabled');
      $('#interests-btn').attr('aria-current', 'true');
    }

    $('#goals-btn').on('click', function (e) {
      e.preventDefault();
      showGoals();
    });

    $('#interests-btn').on('click', function (e) {
      e.preventDefault();
      showInterests();
    });

    $('.toggle-slider').on('click', function (e) {
      e.preventDefault();
      if ($('.goal-selector__section').is(':visible')) {
        showInterests();
      } else {
        showGoals();
      }
    });
  };

  const init = () => {
    initGoalSelector();
  };

  return {
    init,
  };
})();

export default goalSelector;

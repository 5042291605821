import '@babel/polyfill';
import '@accessible360/accessible-slick';
import 'object-fit-images';

import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import facultyTable from './object/faculty-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import tableScroll from './object/scroll-table';
import scrollbarWidth from './object/scrollbar-width';
import sectionNavigation from './object/section-navigation';
import lastWord from './util/last-word';
import mainHeader from './object/header/main-header';
import scrollTrigger from './util/scroll-trigger';
import homepageHero from './object/home-hero';
import focusTriggersScrollEvent from './util/focus-triggers-scroll-event';
import storyCarousel from './object/story-carousel';
import stats from './object/stats';
import goalSelector from './object/goalSelector';
import goToTop from './object/back-to-top';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  scrollbarWidth.init();
  accordion.init();
  inlineVideo.init();
  //instagramLoader.init();
  instagram.init();
  twitterLoader.init();
  facultyTable.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  storyCarousel.init();
  programFinder.init();
  catalog.init();
  tableScroll.init();
  objectFitImages();
  mainHeader.init();
  homepageHero.init();
  sectionNavigation.init();
  stats.init();
  goalSelector.init();
  goToTop.init();
  lastWord.init();
  scrollTrigger.init();
  focusTriggersScrollEvent.init();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
    }, 100);

    $('.img-inline').each(function() {
      if ($(this).find('img').height() < $(this).find('figcaption').outerHeight()) {
        $(this).css('min-height', $(this).find('figcaption').outerHeight());
      } else {
        $(this).css('min-height', $(this).find('img').outerHeight());
      }
    });
  });
});

const searchBox = (()=>{
  const initControls = () => {
    const SEARCH_OPENED_CLASS_NAME = 'search-opened';
    const $body = $('body');
    const $searchOpener = $('.search__opener');
    const $searchForm = $('.search__form');

    $searchOpener.on('click', () => {
      $('.menus-opener.main-menu-active').trigger('click');
      if ($body.hasClass(SEARCH_OPENED_CLASS_NAME)) {
        $body.removeClass(SEARCH_OPENED_CLASS_NAME);
        $searchOpener
          .attr('aria-expanded', 'false')
          .find('.visually-hidden').text('open search');

        $searchForm.attr('aria-hidden', 'true');
      } else {
        $body.addClass(SEARCH_OPENED_CLASS_NAME);
        $searchOpener
          .attr('aria-expanded', 'true')
          .find('.visually-hidden').text('close search');

        $searchForm.attr('aria-hidden', 'false');
        setTimeout(() => {
          $('.search__input').focus();
        }, 600);

      }
    });

  }

  const init = () => {
    initControls();
  }

  return {
    init,
  }
})();

export default searchBox;
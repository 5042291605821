/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.carousel__wrapper').on('init', function () {
      $(this).closest('.carousel').find('.carousel__buttons').appendTo($(this));
    });

    $('.carousel__wrapper').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      prevArrow: $('.prevArrow'),
      nextArrow: $('.nextArrow'),
    });
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;

const menuControls = (() => {
  const ANIMATION_SPEED = 300;
  const OPENED_CLASS_NAME = 'submenu-opened';
  const SUBMENU_SHOW_CLASS_NAME = 'submenu-show';
  let openMenuDelay = null;

  const openLg = ($item, $submenusItems, $submenuOpeners) => {
    const $submenu = $item.find('.submenu');

    $submenuOpeners.removeClass(OPENED_CLASS_NAME)
      .attr('aria-expanded', 'false')
      .find('.visually-hidden').text('open submenu');

      $('.main-menu__item').removeClass(SUBMENU_SHOW_CLASS_NAME);

    $submenusItems
      .stop()
      .slideUp(ANIMATION_SPEED)
      .attr('aria-hidden', 'true');

    $item.addClass(SUBMENU_SHOW_CLASS_NAME);

    openMenuDelay = setTimeout(() => {
      $submenu
        .stop()
        .slideDown(ANIMATION_SPEED)
        .attr('aria-hidden', 'false');
    }, 200);
  }

  const closeLg = ($item) => {
    if (openMenuDelay) {
      clearTimeout(openMenuDelay);
    }

    const $submenu = $item.find('.submenu');

    $item.removeClass(SUBMENU_SHOW_CLASS_NAME);
    $submenu
      .stop()
      .slideUp(ANIMATION_SPEED)
      .attr('aria-hidden', 'true');
  }

  const setHeaderPosition = () => {
    let lastScrollPos = 0;
    let currentScrollPos = 0;
    const $alertHeight = $('.alert').outerHeight() || 0;

    $(window).on('scroll', () => {
      currentScrollPos = $(window).scrollTop();

      if (currentScrollPos > $('.main-header').outerHeight() + $alertHeight) {
        $('.main-header').addClass('sticky-header');
        if (lastScrollPos < currentScrollPos) {
          $('.main-header').addClass('header-hidden');
        } else {
          $('.main-header').removeClass('header-hidden');
        }
      } else {
        $('.main-header').removeClass('sticky-header');
      }
      
      lastScrollPos = currentScrollPos;
    });
  }

  const init = () => {
    const $window = $(window);
    const $submenuOpeners = $('.main-submenu__opener, .secondary-submenu__opener');
    const $mainMenuItems = $('.main-menu__item');
    const $submenusItems = $('.main-header .submenu');

    setHeaderPosition();
    $submenuOpeners.each((index, item) => {
      const $submenuOpener = $(item);
      const $submenu = $submenuOpener.siblings('.submenu');

      $submenuOpener.on('click', (e) => {
        const $siblings = $submenuOpener.parent().siblings();
        if (!$($submenuOpener).hasClass(OPENED_CLASS_NAME)) {
          if ($window.width() > 1024) {
            $submenuOpeners.addClass(OPENED_CLASS_NAME)
              .attr('aria-expanded', 'true')
              .find('.visually-hidden').text('open submenu');

            $submenusItems
              .stop()
              .slideDown(ANIMATION_SPEED)
              .attr('aria-hidden', 'false');
          } else {
            if ($window.width() < 700) {
              $siblings
                .find('.submenu')
                .slideUp(ANIMATION_SPEED)
                .attr('aria-hidden', 'true');
              $submenu
                .stop()
                .slideDown(ANIMATION_SPEED)
                .attr('aria-hidden', 'false');

            } else {
              $siblings
                .find('.submenu')
                .fadeOut(ANIMATION_SPEED)
                .attr('aria-hidden', 'true');
              $submenu
                .stop()
                .fadeIn(ANIMATION_SPEED)
                .attr('aria-hidden', 'false');

              setTimeout(() => {
                $('.main-menu__list').css('min-height', $submenu.outerHeight());
              }, ANIMATION_SPEED);

            }

            $siblings
              .find('button')
              .removeClass(OPENED_CLASS_NAME)
              .attr('aria-expanded', 'false')
              .find('.visually-hidden')
              .text('open submenu');

            $submenuOpener
              .addClass(OPENED_CLASS_NAME)
              .attr('aria-expanded', 'true')
              .find('.visually-hidden').text('close submenu');
          }
        } else {
          if ($window.width() < 700) {
            $submenu
              .stop()
              .slideUp(ANIMATION_SPEED)
              .attr('aria-hidden', 'true');
          } else {
            $submenu
              .stop()
              .fadeOut(ANIMATION_SPEED)
              .attr('aria-hidden', 'true');
          }
          $submenuOpener
            .removeClass(OPENED_CLASS_NAME)
            .attr('aria-expanded', 'false')
            .find('.visually-hidden').text('open submenu');
       
        }
      });
    });

    $mainMenuItems.each((index, item) => {
      const $item = $(item);
      const $mainLink = $item.find('.main-menu__link');

      $mainLink.on('focus', () => {
        if ($window.width() > 1024 && !$item.hasClass(SUBMENU_SHOW_CLASS_NAME)) {
          openLg($item, $submenusItems, $submenuOpeners);
        }
      });

      $('.secondary-menu__link').first().on('keydown', (event) => {
        if ($window.width() > 1024 && !event.shiftKey && event.key === 'Tab') {
          closeLg($('.main-menu > ul.main-menu__list .main-menu__item').last());
        }
      });

      $item.on('mouseenter', () => {
        if ($window.width() > 1024) {
          openLg($item, $submenusItems, $submenuOpeners);
        }
      });

      $item.on('mouseleave', () => {
        if ($window.width() > 1024) {
          closeLg($item);
        }
      });
    });

    const $menusOpener = $('.menus-opener');
    const $mainMenuLastLink = $('.main-menu .submenu__link').last();
    const $lastSecondaryMenuButton = $('.secondary-menu .secondary-submenu__opener').last();
    const $lastSecondaryMenuElement = $('.secondary-menu .submenu__link').last();
    const $searchOpener = $('.search__opener');

    $menusOpener.on('keydown', (event) => {
      if ($window.width() < 1025) {
        if (event.shiftKey && event.keyCode === 9 && $menusOpener.hasClass('main-menu-active')) {
          event.preventDefault();

          if ($lastSecondaryMenuElement.is(':visible')) {
            $lastSecondaryMenuElement.focus();
          } else {
            $lastSecondaryMenuButton.focus();
          }

        }

        // if (!event.shiftKey && event.keyCode === 9 && !$menusOpener.hasClass('main-menu-active')) {
        //   event.preventDefault();
        //   $searchOpener.focus();
        // }
      }
    });

    $searchOpener.on('keydown', (event) => {
      if ($window.width() < 1025) {
        if (event.shiftKey && event.keyCode === 9 && !$menusOpener.hasClass('main-menu-active')) {
          event.preventDefault();
          $menusOpener.focus();
        }
      }
    });

    $lastSecondaryMenuButton.on('keydown', (event) => {
      if ($window.width() < 1025) {
        if (!event.shiftKey && event.keyCode === 9 && !$(event.target).hasClass('submenu-opened')) {
          event.preventDefault();
          $menusOpener.focus();
        }
      }
    });

    $lastSecondaryMenuElement.on('keydown', (event) => {
      if ($window.width() < 1025) {
        if (!event.shiftKey && event.keyCode === 9 && $body.hasClass('main-menu-active')) {
          event.preventDefault();
          $menusOpener.focus();
        }
      }
    });

    $mainMenuLastLink.on('keydown', (event) => {
      if ($window.width() > 1024) {
        if (!event.shiftKey && event.keyCode === 9) {
          $submenuOpeners.removeClass(OPENED_CLASS_NAME)
            .attr('aria-expanded', 'false')
            .find('.visually-hidden').text('open submenu');
  
            $('.main-menu__item').removeClass(SUBMENU_SHOW_CLASS_NAME);
  
          $submenusItems
            .stop()
            .slideUp(ANIMATION_SPEED)
            .attr('aria-hidden', 'true');
        }
      }
    });

    $(document).on('keyup', (e) => {
      if (e.key !== 'Escape') {
        return;
      }

      $mainMenuItems.each((index, item) => {
        const $item = $(item);
        closeLg($item);
      });

      $submenuOpeners.each((index, item) => {
        const $submenuOpener = $(item);
        const $submenu = $submenuOpener.siblings('.submenu');

        $submenuOpener
            .removeClass(OPENED_CLASS_NAME)
            .attr('aria-expanded', 'false')
            .find('.visually-hidden').text('open submenu');

          $submenu
            .stop()
            .slideUp(ANIMATION_SPEED)
            .attr('aria-hidden', 'true');
      });

    });
  };

  return {
    init,
  }
})();

export default menuControls;
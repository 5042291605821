
const homepageHero = (() => {
  const initVideoControls = () => {
    const PAUSED_CLASSNAME = 'is-paused';
    const $hero = $('.hero-home');
    const videoPlayer = $hero.find('.hero__video')[0];
    const $playButton = $hero.find('.play-video-btn');

    if (!videoPlayer) {
      return;
    }

    videoPlayer.play();
    $hero.addClass('loaded');
    $playButton.find('.visually-hidden').text('pause video');

    $playButton.on('click', () => {
      if (!$playButton.hasClass(PAUSED_CLASSNAME)) {
        videoPlayer.pause();
        $playButton.addClass(PAUSED_CLASSNAME);
        $playButton.find('.visually-hidden').text('play video');
      } else {
        videoPlayer.play();
        $playButton.removeClass(PAUSED_CLASSNAME);
        $playButton.find('.visually-hidden').text('pause video');
      }
    });
  }

  const init = () => {
    const $window = $(window);
    const $hero = $('.hero--home');

    $window.on('load', () => {
      $hero.addClass('is-loaded');
    });

    initVideoControls();
  }

  return {
    init,
  }
})();

export default homepageHero;
const sectionNavigation = (() => {
  const init = () => {
    const ANIMATION_SPEED = 300;
    const DESKTOP_BREAKPOINT = 1025;
    const NAV_OPENED_CLASSNAME = 'opened';
    const $window = $(window);
    const $sectionNavBox = $('.section-nav');
    const $navOpener = $sectionNavBox.find('.section-nav__toggle');
    const $navList = $sectionNavBox.find('.section-nav__list');

    if ($window.width() < DESKTOP_BREAKPOINT) {
      $navOpener.attr('aria-expanded', 'false');
      $navList.attr('aria-hidden', 'true');
    }

    $navOpener.on('click', () => {
      if (!$navOpener.hasClass(NAV_OPENED_CLASSNAME)) {
        $navOpener.addClass(NAV_OPENED_CLASSNAME);
        if ($('.hero').hasClass('has-image')) {
          $navList.delay(600).slideDown(ANIMATION_SPEED);
        } else {
          $navList.slideDown(ANIMATION_SPEED);
        }

        $navOpener.attr('aria-expanded', 'true');
        $navList.attr('aria-hidden', 'false');
        $('.hero').addClass('expanded');
      } else {
        $navOpener.removeClass(NAV_OPENED_CLASSNAME);
        $navList.slideUp(ANIMATION_SPEED);
        $navOpener.attr('aria-expanded', 'false');
        $navList.attr('aria-hidden', 'true');
        $('.hero').removeClass('expanded');
      }
    });
  };

  return {
    init,
  };
})();

export default sectionNavigation;

/**
 * @param {*} $
 * @returns {init} Determine and run if scroll trigger elements present
 */

const scrollTrigger = (() => {
  const init = () => {
    const selectors = $('.scroll-animate');

    selectors.each((i, elem) => {
      $(window).on('scroll load', () => {
        const topofDiv = $(elem).offset().top;
        const a = $(window).scrollTop() + ($(window).height() * 0.7);

        if (a > topofDiv) {
          $(elem).addClass('js-active');
        }
      });
    });
  };

  return {
    init,
  };
})();

export default scrollTrigger;

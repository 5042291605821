/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

 const stats = (() => {
  // Render carousel
  function matchCtaHeight() {
    $('.stat3up').each(function(){
      let maxHeight = 0;
      $(this)
        .find('.stat3up__cta')
        .each(function () {
          $(this).css('height', '');
          const currHeight = $(this).outerHeight();

          if (currHeight > maxHeight) {
            maxHeight = currHeight;
          }
        });
        $(this)
          .find('.stat3up__cta').css('min-height', maxHeight);
    });
  }

  const init = () => {
    if (!$('.stat3up').length) {
      return;
    }

    $(window).on('load resize', function(){
      matchCtaHeight();
    });
  };

  return {
    init,
  };
})();

export default stats;
/**
 * Accordion-simple displays main navigation
 * @param {*} $
 * @returns {init} Determine and run if main-nav present
 */

// eslint-disable-next-line global-require
require('../vendor/scrollsync.js');

const $ = require('jquery');

const tableScroll = (() => {
  // Add table scroll buttons to DOM
  const $tableScroll = $('table .table-scroller');
  const $tableWrap = $('.table-block__holder');
  const $table = $('.table-block table');

  function tableNext() {
    const elWidth = $(this).siblings($table).parent().width();

    $(this)
      .siblings($table)
      .find($tableScroll)
      .animate(
        {
          scrollLeft: `+=${elWidth}`,
        },
        300,
        'swing',
      );
  }

  function tablePrev() {
    const elWidth = $(this).siblings($table).parent().width();
    $(this)
      .siblings($table)
      .find($tableScroll)
      .animate(
        {
          scrollLeft: `-=${elWidth}`,
        },
        300,
        'swing',
      );
  }

  // handles updating certain table attributes on load, resize, or scroll
  function watchScrollTables() {
    $tableWrap.each((i) => {
      const $thisTable = $($tableWrap[i]);
      const $tableScrollers = $thisTable.find('.syncscroll.table-scroller');

      // takes care of adding / removing aria-hidden attribute from table scrollers
      if ($thisTable.hasClass('js-scroll--right')) {
        $thisTable.find('.table-block__next').removeAttr('aria-hidden');
      } else {
        $thisTable.find('.table-block__next').attr('aria-hidden', true);
      }
      if ($thisTable.hasClass('js-scroll--left')) {
        $thisTable.find('.table-block__prev').removeAttr('aria-hidden');
      } else {
        $thisTable.find('.table-block__prev').attr('aria-hidden', true);
      }

      if (
        $thisTable.hasClass('js-scroll--right') ||
        $thisTable.hasClass('js-scroll--left')
      ) {
        // if the table is scrollable, the scroll region either needs to be focusable or have content that is focusable
        $tableScrollers.each((j) => {
          const $thisScroller = $($tableScrollers[j]);

          // checking that no focusable content before adding tabindex="0" to the scroll region
          if (!$thisScroller.find('a').length) {
            $thisScroller.attr('tabindex', 0);
          }
        });
      } else {
        $tableScrollers.each((j) => {
          const $thisScroller = $($tableScrollers[j]);

          // removing tabindex when the table is no longer scrollable
          if ($thisScroller.attr('tabindex')) {
            $thisScroller.removeAttr('tabindex');
          }
        });
      }
    });
  }

  function tableInit() {
    const wrapWidth = $tableWrap.outerWidth();
    const tableWidth = $tableWrap.find('tr').outerWidth();

    if (wrapWidth < tableWidth) {
      $tableWrap.addClass('js-scroll--right');
    } else {
      $tableWrap.removeClass('js-scroll--left js-scroll--right');
    }

    watchScrollTables();
  }

  function tableUpdateScroll() {
    const scrollLeft = $(this).scrollLeft();
    const scrollWidth = $(this)[0].scrollWidth - $tableWrap[0].clientWidth;
    const $thisWrapper = $(this).closest('.table-block__holder');
    if (scrollLeft <= 0 && scrollWidth > 0) {
      // If at the begin scroll pos
      $thisWrapper.addClass('js-scroll--right');
      $thisWrapper.removeClass('js-scroll--left');
    } else if (Math.ceil(scrollLeft) >= scrollWidth && scrollWidth > 0) {
      // If at the end scroll pos
      $thisWrapper.removeClass('js-scroll--right').addClass('js-scroll--left');
    } else {
      $thisWrapper.addClass('js-scroll--right js-scroll--left');
    }

    watchScrollTables();
  }

  const init = () => {
    if ($tableWrap.length) {
      $tableWrap.prepend(
        '<button class="table-block__prev" aria-hidden="true">Scroll Table Right</button>\n' +
          '<button class="table-block__next" aria-hidden="true">Scroll Table Left</button>',
      );

      $('.table-block__next').on('click', tableNext);
      $('.table-block__prev').on('click', tablePrev);

      $(window).on('load resize', tableInit);
      $tableScroll.on('scroll', tableUpdateScroll);
    }
  };

  return {
    init,
  };
})();

export default tableScroll;
